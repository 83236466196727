import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const LogoWrapper = styled.a`
  position: absolute;
  top: 0px;
  left: 70px;
  z-index: 100;
`;

export const MobileWrapper = styled.div`
  background-color: #0d0f19;
  color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;

  div {
    position: relative;
    width: 100%;
    height: 106px;
    left: 0px;
    top: 0px;
    background: #181b28;
    margin-bottom: 200px;
    z-index: 1;
  }

  h2 {
    font-family: "riffic", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    padding: 0 25px;
    margin-bottom: 23px;
  }

  p {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    padding: 0 25px;
    margin-bottom: 64px;
  }

  span {
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding: 0 25px;
    display: block;
  }

  strong {
    color: #fb0300;
  }

  @media (min-width: 450px) {
    display: none;
  }
`;

export const Hello = styled.img`
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 0;
`;

export const Logo = styled.img`
  margin: 50px auto 0;
  display: block;
`;
