import React, { useState } from 'react';
import { Analytics } from '@vercel/analytics/react';

import Builder from './containers/builder';
import Scene from './containers/scene';
import Loader from './components/Loader';

import GlobalStyles from './styles/global.styles';
import * as Styles from './styles/general.styles';

import arrowSrc from "./assets/logo.svg";
import mobileSrc from "./assets/mobile.svg";
import logoSrc from "./assets/logo-mobile.svg";


import { GlobalStateProvider } from './components/GlobalStateProvider';

function App() {
  return (
    <>
      <GlobalStyles />
      <Styles.MobileWrapper>
        <div />
        <Styles.Hello src={mobileSrc} alt="Mobile" />
        <h2>Hey there! <br/> Well, it looks like you are on mobile, aren't you!?</h2>
        <p>
          Unfortunately, this experience is currently optimized for <strong>desktop use only</strong>. <br /><br />
          Stay tuned, and thanks for your patience!
        </p>
        <span>*Just so you know, we're working on a mobile version at our own relaxed pace, so you can eventually enjoy karaoke tool even on the go.</span>
      </Styles.MobileWrapper>
      <GlobalStateProvider>
        <Loader />
        <Styles.Wrapper>
          <Styles.LogoWrapper href='/'>
            <Styles.Logo src={arrowSrc} alt="Logo" />
          </Styles.LogoWrapper>

          <Scene />
          <Builder />
        </Styles.Wrapper>
      </GlobalStateProvider>

      <Analytics />
    </>
  );
}

export default App;