import styled from "styled-components";

import restartSrc from "../assets/restart.svg";
import shareSrc from "../assets/share.svg";

export const FinalStepWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Header = styled.a`
  position: absolute;
  top: 45px;
  right: 70px;
  z-index: 100;
  color: white;
  font-family: "riffic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  transition: color 0.2s, fill 0.2s;

  svg {
    position: relative;
    top: 10px;
    left: 20px;
  }
  &:hover {
    color: #FB0300;

    path {
      fill: #FB0300;
    }
  }
`;

export const Footer = styled.div`
  position: absolute;
  display: flex;
  bottom: 90px;
  width: 100%;
  justify-content: space-around;
`;

export const Credits = styled.div`
  color: #fff;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  min-width: 372px;

  a {
    display: flex;
    align-items: center;
    transition: color 0.2s;

    &:hover {
      color: #FB0300;
    }
  }

  img {
    margin-right: 8px;
  }
`;

export const Play = styled.div`
  font-family: "riffic", sans-serif;
  background: #fff;
  color: #020410;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  height: 160px;
  width: 160px;
  display: flex;
  font-size: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  gap: 15px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #FB0300;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  font-family: "riffic", sans-serif;
  color: white;
  font-size: 20px;
  align-items: center;
  gap: 60px;
`;

export const Action = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
  transition: color 0.2s, fill 0.2s;

  &:hover {
    color: #FB0300;

    path {
      fill: #FB0300;
    }
  }
`;


export const Copied = styled.p`
  font-family: "riffic", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 50px;
  right: 0;
`;
