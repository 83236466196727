import React, { useRef, useEffect, useState } from "react";
import bodymovin from "bodymovin";
import { AnimatePresence } from "framer-motion";

import * as Styles from "./Loader.styles";

let loaderAnim = null;

function Loader() {
  const [isLoaded, setIsLoaded] = useState(false);
  const backgroundContainerRef = useRef();

  useEffect(() => {
    if (backgroundContainerRef.current && loaderAnim !== undefined) {
      loaderAnim = bodymovin
        .loadAnimation({
          container: backgroundContainerRef.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          name: "loader",
          path: "/animations/loader.json",
        })
        .setSpeed(1);
    }
  }, [backgroundContainerRef]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      {
        !isLoaded &&
        <Styles.Wrapper
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div ref={backgroundContainerRef} />
        </Styles.Wrapper>
      }
    </AnimatePresence>
  );
}

export default Loader;
