import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export default createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
  }

  strong,
  b {
    font-weight: 700;
  }

  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: 'Manrope', sans-serif;
    width: 100%;
    overflow: hidden;
  }

  button {
    border-width: 0;
  }

  a {
    text-decoration: none;
    color: white;
  }
`;