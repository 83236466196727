import React, { useState, useContext} from 'react';

const GlobalStateContext = React.createContext();

export function GlobalStateProvider({ children }) {
  const [globalState, setGlobalState] = useState({
    position: 1,
    genre: '',
    description: '',
    artist: '',
    isFinalAnimation: false,
    isEnd: false,
    audio: {},
    isPlaying: true,
    song: ''
  });

  return (
    <GlobalStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  const context = useContext(GlobalStateContext);

  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }

  return context;
}