import React, { useState, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { useGlobalState } from './GlobalStateProvider';
import * as Styles from './ScrollingTextAnimation.styles';

let yPosition = 0;

export const ScrollingTextAnimation = () => {
  const { globalState, setGlobalState } = useGlobalState();
  const [isReady, setIsReady] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const wordContainerRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 2200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldScroll(true);
    }, globalState.audio.delay);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    let timer = null;

    if(shouldScroll && wordContainerRef.current && !globalState.isEnd && globalState.isPlaying) {
      timer = setInterval(() => {
        yPosition -= 1;
        wordContainerRef.current.style.transform = `translateY(${yPosition}px)`;
      }, 40)
    }

    return () => clearInterval(timer);
  }, [shouldScroll, globalState])

  return (
    <Styles.Wrapper>
      <Styles.Container ref={wordContainerRef}>
        <AnimatePresence>
            {
              isReady &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                <h2>WAIT FOR IT</h2>
                <p style={{ whiteSpace: "break-spaces", lineHeight: '130%' }}>{globalState.song}</p>
              </motion.div>
            }
        </AnimatePresence>
      </Styles.Container>
    </Styles.Wrapper>
  );
};