import React, { useState, useEffect, useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { useGlobalState } from '../GlobalStateProvider';

let currentPosition = 1;
let direction = 1;

export const SceneModel = () => {
  const { globalState, setGlobalState } = useGlobalState();

  const gltf = useLoader(GLTFLoader, '/assets/scene4.gltf');

  const [isAnimating, setIsAnimating] = useState(false);
  const [isInitialAnimation, setIsInitialAnimaiton] = useState(true);
  const [rotationTarget, setRotationTarget] = useState(false);

  let speedMultiplier = 0.1;
  const meshRef = useRef();

  useFrame((state, delta) => {
    const mesh = meshRef.current;

    if (isAnimating) {
      if (direction === 1 && mesh.rotation.y > rotationTarget) {
        mesh.rotation.y -= delta * 3;

        if (mesh.rotation.y <= rotationTarget) {
          mesh.rotation.y = rotationTarget;
          setIsAnimating(false);
        }
      }

      if (direction === -1) {
        mesh.rotation.y += delta * 3;

        if (mesh.rotation.y >= rotationTarget) {
          mesh.rotation.y = rotationTarget;
          setIsAnimating(false);
        }
      }
    }

    if (isInitialAnimation) {
      mesh.rotation.y -= (delta * speedMultiplier);

      if (mesh.rotation.y <= -(2 * Math.PI) && globalState.position !== 2) {
        mesh.rotation.y = 0;
      }

      if (globalState.position === 2) {
        speedMultiplier = 5;

        if (mesh.rotation.y <= -(2 * Math.PI)) {
          setIsInitialAnimaiton(false);
          mesh.rotation.y = 0;
          speedMultiplier = 0.9;
        }
      }
    }
  });

  useEffect(() => {
    if (currentPosition > globalState.position) direction = -1;
    else direction = 1;

    if (!isAnimating && (globalState.position > 2 && globalState.position <= 5)) {
      setIsAnimating(true);
      setRotationTarget((meshRef.current.rotation.y - (Math.PI / 2)));
    }

    if (direction === -1) {
      setIsAnimating(true);
      setRotationTarget((meshRef.current.rotation.y + (Math.PI / 2)));
    }

    currentPosition = globalState.position;
  }, [globalState.position])

  return <primitive
    ref={meshRef}
    object={gltf.scene}
    scale={1}
    rotation={[0, 0, 0]}
  />;
}