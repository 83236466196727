import React, { useRef } from 'react';
import * as THREE from 'three';
import { SpotLight } from '@react-three/drei';

export const SpotLightHelper = ({position, helperColor = 'red'}) => {
  const light = useRef();
  // useHelper(light, THREE.SpotLightHelper, helperColor);

  return <SpotLight
    position={position}
    intensity={10}
    color="red"
    ref={light}
    distance={10}
    angle={0.4}
    castShadow
  />
}