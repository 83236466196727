import React, { useState, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { PerspectiveCamera } from '@react-three/drei';

import { useGlobalState } from '../../components/GlobalStateProvider';

const ANIMATIONS = [
  'initial',
  'end'
]

export const MainCamera = () => {
  const [cameraPosition, setCameraPosition] = useState({x: 0, y:1, z:5});
  const [animationStage, setAnimationStage] = useState(ANIMATIONS[0]);
  const { globalState, setGlobalState } = useGlobalState();

  useFrame((state, delta) => {
    // if (animationStage === ANIMATIONS[0]) {
    //   let newPosition = cameraPosition.z - (2.5 * delta);

    //   if (newPosition <= 5) {
    //     setAnimationStage(false);
    //     newPosition = 5;
    //   }

    //   setCameraPosition({x: 0, y:1, z: newPosition});
    // }

    if (animationStage === ANIMATIONS[1]) {
      let newPositionZ = cameraPosition.z - (1 * delta);
      let newPositionY = cameraPosition.y + (0.6 * delta);

      if (newPositionZ <= 2.8) {
        newPositionZ = 2.8;
      }

      if (newPositionY >= 1.8) {
        newPositionY = 1.8;
      }

      if (newPositionY === 2.8 && newPositionZ === 1.8) {
        setAnimationStage(false);
      }

      setCameraPosition({x: 0, y: newPositionY, z: newPositionZ});
    }
  });

  useEffect(() => {
    if (globalState.isFinalAnimation) setAnimationStage(ANIMATIONS[1]);
  }, [globalState])

  return <PerspectiveCamera makeDefault position={[cameraPosition.x, cameraPosition.y, cameraPosition.z]} rotation={[0, 0, 0]} />

}