import styled from 'styled-components';
import { motion } from 'framer-motion';

import backgroundSrc from "../assets/background.svg";

export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 40px;
`;

export const Title = styled.div`
  color: #fff;
  font-family: 'riffic', sans-serif;
  font-size: 62px;
  position: relative;
  padding: 67px 40px;
  margin-bottom: 70px;
  text-align: right;
  position: relative;
  z-index: 10;
  top: 200px;
  left: -90px;

  span {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 30px;
    z-index: 1;
    margin-bottom: 20px;
    padding-right: 50px;
  }

  strong {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding-right: 50px;
    letter-spacing: 2px;
    max-width: 430px;
  }

  svg {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 40px;
    transform: rotate(5deg);
  }
`;

export const Intro = styled.div`
  background: url(${backgroundSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 690px 592px;
  font-family: 'Manrope', sans-serif;
  display: block;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 690px;
  height: 592px;
  padding: 320px 105px 0;
  position: relative;
  top: -120px;

  p {
    text-align: center;
  }

  strong {
    font-family: 'riffic', sans-serif;
    text-align: center;
    display: block;
    margin-bottom: 67px;
    font-weight: 700;
  }
`;

export const CTA = styled.button`
  cursor: pointer;
  font-family: 'riffic', sans-serif;
  font-size: 20px;
  position: relative;
  background-color: transparent;
  min-width: 220px;
  display: block;
  margin-left: auto;
  margin-right: 50px;

  div {
    display: block;
    color: #FFFFFF;
    border-radius: 88px;
    background: #FB0300;
    z-index: 1;
    position: relative;
    padding: 16px 0;
    transition: color 0.2s;

    &:hover {
      color: #fff;
      background-color: #FB0300;
    }
  }

  svg {
    position: absolute;
    left: -45px;
    top: -50px;
    height: 150px !important;
    width: 300px !important;
    z-index: 0;
    pointer-events: none;
  }
`;

export const Note1 = styled.div`
  height: 53px;
  width: 50px;
  position: absolute;
  bottom: 130px;
`;

export const Note2 = styled.div`
  height: 53px;
  width: 50px;
  position: absolute;
  right: 100px;
  bottom: 120px;
`;