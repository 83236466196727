import React, { useRef, useEffect } from "react";
import bodymovin from "bodymovin";

import * as Styles from "./Step.styles";

function AnimatedStep({ step = 0 }) {
  const backgroundContainerRef = useRef();
  let lottie = null;

  useEffect(() => {
    if (backgroundContainerRef.current && !lottie) {
      lottie = bodymovin
        .loadAnimation({
          container: backgroundContainerRef.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          name: "star-background-global",
          path: "/animations/star.json",
        })
        .setSpeed(2);
    }
  }, [backgroundContainerRef, lottie]);

  return (
    <Styles.StepWrapper>
      <Styles.BackgroundContainer ref={backgroundContainerRef} />
      <Styles.CurrentStep>{step}</Styles.CurrentStep>
      <Styles.MaxSteps>/3</Styles.MaxSteps>
    </Styles.StepWrapper>
  );
}

export default AnimatedStep;
