import React from 'react';

import { Canvas } from '@react-three/fiber';

import { useGlobalState } from '../components/GlobalStateProvider';

import { ScrollingTextAnimation } from '../components/ScrollingTextAnimation';

import { SceneModel } from '../components/3d/SceneModel';
import { PointLight } from '../components/3d/PointLight';
import { SpotLightHelper } from '../components/3d/SpotLight';
import { MainCamera } from '../components/3d/Camera';

import * as Styles from './scene.styles';


function Scene() {
  const { globalState, setGlobalState } = useGlobalState();

  return (
    <Styles.Wrapper width={globalState.isFinalAnimation || globalState.position === 1 ? '100%' : '70%'}>
      <Canvas>
        <ambientLight intensity={0.1} />
        <PointLight position={[1, 3.5, 1]} />
        <PointLight position={[-1, 3.5, -1]} />
        <PointLight position={[1, 3.5, -1]} />
        <PointLight position={[-1, 3.5, 1]} />

        <PointLight position={[0, 3.5, 1]} lightColor = "white" />
        <PointLight position={[1, 3.5, 0]} lightColor = "white" />
        <PointLight position={[0, 3.5, -1]} lightColor = "white" />
        <PointLight position={[-1, 3.5, 0]} lightColor = "white" />

        <SpotLightHelper position={[0, 3.5, 5]} />
        <SpotLightHelper position={[5, 3.5, 0]} helperColor='yellow' />
        <SpotLightHelper position={[0, 3.5, -5]} helperColor='pink' />
        <SpotLightHelper position={[-5, 3.5, 0]} helperColor='blue' />

        <MainCamera />
        <SceneModel />
      </Canvas>

      {
        globalState.position === 5 &&
        <ScrollingTextAnimation width="10" height="20" speed="1" />
      }
    </Styles.Wrapper>
  );
}

export default Scene;