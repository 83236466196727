import React, { useEffect, useState } from 'react';
import {Howl} from 'howler';

import { useGlobalState } from '../components/GlobalStateProvider';

import linkedinSrc from "../assets/linkedin.svg";
import pauseSrc from "../assets/pause.svg";
import playSrc from "../assets/play.svg";

import * as Styles from './FinalStep.styles';

let isSoundSet = false;

function FinalStep() {
  const { globalState, setGlobalState } = useGlobalState();
  const [gameState, setGameState] = useState(true);
  const [copied, setCopied] = useState(false);
  const [sound, setSound] = useState(null);

  const handleGameState = () => {
    gameState ? sound.pause() : sound.play();
    setGameState(!gameState);
    setGlobalState({
      ...globalState,
      isPlaying: !gameState
    });
  };

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(globalState.song)
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000)
  };

  useEffect(() => {
    if (!isSoundSet) {
      var audio = new Howl({
        src: [globalState.audio.url],
        autoplay: false,
        loop: true,
        volume: 0.5
      });

      isSoundSet = true;
      setSound(audio);

      setTimeout(() => {
        audio.play();
      }, 1000)

      setGlobalState({
        ...globalState,
        isPlaying: true
      });
    }

  }, []);

  return (
    <Styles.FinalStepWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{duration: 0.5}}
      key="step-final-wrapper"
    >
      <Styles.Header href='/' title="New song">
        COMPOSE NEW SONG
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.0003 3.33341C22.3059 3.33341 24.4726 3.77119 26.5003 4.64675C28.5281 5.5223 30.292 6.70953 31.792 8.20841C33.292 9.70841 34.4792 11.4723 35.3537 13.5001C36.2281 15.5279 36.6659 17.6945 36.667 20.0001C36.667 22.3056 36.2292 24.4723 35.3537 26.5001C34.4781 28.5279 33.2909 30.2917 31.792 31.7917C30.292 33.2917 28.5281 34.479 26.5003 35.3534C24.4725 36.2279 22.3059 36.6656 20.0003 36.6667C17.6948 36.6667 15.5281 36.229 13.5003 35.3534C11.4725 34.4779 9.70866 33.2906 8.20866 31.7917C6.70866 30.2917 5.52088 28.5279 4.64533 26.5001C3.76977 24.4723 3.33255 22.3056 3.33366 20.0001C3.33366 17.6945 3.77144 15.5279 4.64699 13.5001C5.52255 11.4723 6.70977 9.70841 8.20866 8.20841C9.70866 6.70841 11.4726 5.52064 13.5003 4.64508C15.5281 3.76953 17.6948 3.3323 20.0003 3.33341ZM20.0003 6.66675C16.2781 6.66675 13.1253 7.95841 10.542 10.5417C7.95866 13.1251 6.66699 16.2779 6.66699 20.0001C6.66699 23.7223 7.95866 26.8751 10.542 29.4584C13.1253 32.0417 16.2781 33.3334 20.0003 33.3334C23.7225 33.3334 26.8753 32.0417 29.4587 29.4584C32.042 26.8751 33.3337 23.7223 33.3337 20.0001C33.3337 16.2779 32.042 13.1251 29.4587 10.5417C26.8753 7.95842 23.7226 6.66675 20.0003 6.66675Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.7615 20C25.4722 22.9911 27.0396 25.6884 26.3639 26.3641C25.6882 27.0398 22.9908 25.4724 19.9998 22.7617C17.0088 25.4724 14.3114 27.0398 13.6358 26.3641C12.9601 25.6884 14.5274 22.9911 17.2382 20C14.5275 17.0091 12.9603 14.3118 13.6359 13.6362C14.3116 12.9605 17.0089 14.5278 19.9998 17.2384C22.9908 14.5278 25.688 12.9605 26.3637 13.6362C27.0394 14.3119 25.4721 17.0091 22.7615 20Z" fill="white"/>
        </svg>

      </Styles.Header>
      <Styles.Footer>
        <Styles.Credits>
          Created during lazy long weekend for fun
          <a href="https://www.linkedin.com/in/krzysztof-wyrzykowski-12257169/" target="_blank">
            <img src={linkedinSrc} alt="linkedin" />
            Kryz /Development
          </a>
          <a href="https://www.linkedin.com/in/veronicaostrowska/" target="_blank">
            <img src={linkedinSrc} alt="linkedin" />
            Vera /Design
          </a>
        </Styles.Credits>

        <Styles.Play onClick={handleGameState}>
          <img src={gameState ? pauseSrc : playSrc} alt="Pause" />
          <span>{gameState ? 'PAUSE' : 'PLAY'}</span>
        </Styles.Play>

        <Styles.ActionWrapper>
          <Styles.Action onClick={() => window.location.reload()}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.9997 33.6667C7.79467 33.6667 0.333008 26.205 0.333008 17C0.333008 7.79504 7.79467 0.333374 16.9997 0.333374C26.2047 0.333374 33.6663 7.79504 33.6663 17C33.6663 26.205 26.2047 33.6667 16.9997 33.6667ZM23.7783 23.2016C24.4135 24.345 25.9669 24.5751 26.6935 23.4875C27.823 21.7967 28.4997 19.8265 28.6343 17.7676C28.8256 14.8421 27.9083 11.9518 26.0651 9.67183C24.2219 7.3919 21.5879 5.88952 18.6872 5.46364C15.7865 5.03777 12.8318 5.71962 10.411 7.37352C7.99028 9.02742 6.28096 11.5321 5.62322 14.3892C4.96548 17.2462 5.40754 20.2462 6.86143 22.7921C8.31532 25.338 10.6745 27.2432 13.4694 28.1286C15.6853 28.8305 18.0463 28.8506 20.2524 28.2086C21.1927 27.9349 21.5413 26.8433 21.0661 25.987C20.6437 25.2258 19.714 24.9292 18.8655 25.1241C17.6319 25.4075 16.343 25.4077 15.0976 25.1157C13.3511 24.7063 11.7834 23.7442 10.6274 22.3725C9.4714 21.0008 8.7889 19.2926 8.68134 17.502C8.57377 15.7114 9.04689 13.9338 10.0304 12.4336C11.0138 10.9334 12.4552 9.79055 14.1401 9.17499C15.825 8.55943 17.6636 8.50401 19.3826 9.01696C21.1015 9.52991 22.6091 10.5838 23.6811 12.0221C24.7532 13.4604 25.3325 15.2062 25.333 17H22.0325C21.27 17 20.7881 17.8192 21.1584 18.4857L23.7783 23.2016Z" fill="white"/>
            </svg>

            RESTART
          </Styles.Action>

          <Styles.Action onClick={copyToClipboard}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.0003 3.33329C22.3059 3.33329 24.4726 3.77107 26.5003 4.64662C28.5281 5.52218 30.292 6.7094 31.792 8.20829C33.292 9.70829 34.4792 11.4722 35.3537 13.5C36.2281 15.5277 36.6659 17.6944 36.667 20C36.667 22.3055 36.2292 24.4722 35.3537 26.5C34.4781 28.5277 33.2909 30.2916 31.792 31.7916C30.292 33.2916 28.5281 34.4788 26.5003 35.3533C24.4725 36.2277 22.3059 36.6655 20.0003 36.6666C17.6948 36.6666 15.5281 36.2288 13.5003 35.3533C11.4725 34.4777 9.70866 33.2905 8.20866 31.7916C6.70866 30.2916 5.52088 28.5277 4.64533 26.5C3.76977 24.4722 3.33255 22.3055 3.33366 20C3.33366 17.6944 3.77144 15.5277 4.64699 13.5C5.52255 11.4722 6.70977 9.70829 8.20866 8.20829C9.70866 6.70829 11.4726 5.52052 13.5003 4.64496C15.5281 3.7694 17.6948 3.33218 20.0003 3.33329ZM20.0003 6.66662C16.2781 6.66662 13.1253 7.95829 10.542 10.5416C7.95866 13.125 6.66699 16.2777 6.66699 20C6.66699 23.7222 7.95866 26.875 10.542 29.4583C13.1253 32.0416 16.2781 33.3333 20.0003 33.3333C23.7225 33.3333 26.8753 32.0416 29.4587 29.4583C32.042 26.875 33.3337 23.7222 33.3337 20C33.3337 16.2777 32.042 13.125 29.4587 10.5416C26.8753 7.95829 23.7226 6.66662 20.0003 6.66662Z" fill="white"/>
              <path d="M28.2929 19.7071C28.6834 19.3166 28.6834 18.6834 28.2929 18.2929L23.7071 13.7071C23.0771 13.0771 22 13.5233 22 14.4142V16C17.1023 16.6997 14.1628 19.3575 12.4964 22.6033C12.0043 23.5619 13.4283 24.3683 14.3419 23.797C16.402 22.5089 18.9156 21.9 22 21.9V23.5858C22 24.4767 23.0771 24.9229 23.7071 24.2929L28.2929 19.7071Z" fill="white"/>
            </svg>
            COPY TEXT
            {copied && <Styles.Copied>Copied!</Styles.Copied>}
          </Styles.Action>
        </Styles.ActionWrapper>
      </Styles.Footer>
    </Styles.FinalStepWrapper>
  );
}

export default FinalStep;