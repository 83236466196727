import React, { useRef, useEffect } from 'react';
import bodymovin from 'bodymovin';
import {Howl} from 'howler';

import { useGlobalState } from '../components/GlobalStateProvider';

import AnimatedStep from './AnimatedStep';
import audioConfig from '../audioConfig';

import * as Styles from './Step.styles';

function Step1() {
  const { globalState, setGlobalState } = useGlobalState();
  const backgroundContainerRef = useRef();
  let lottie = null;

  const handleGenreClick = (genre) => {
    const index = Math.floor(Math.random() * 5);
    const genreConfig = audioConfig[genre];

    new Howl({src: [genreConfig[index].url]});
    setGlobalState({ ...globalState, position: globalState.position + 1, audio: genreConfig[index], genre: genre});
  };

  const handleMouseOver = (event) => {
    bodymovin.loadAnimation({
      container: event.currentTarget,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      name: 'mouseover',
      path: '/animations/notes.json'
    }).setSpeed(2);
  }

  useEffect(() => {
    if (backgroundContainerRef.current && !lottie) {
      lottie = bodymovin.loadAnimation({
        container: backgroundContainerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'star-background',
        path: '/animations/star.json'
      }).setSpeed(2);
    }
  }, [backgroundContainerRef, lottie]);

  return (
    <Styles.Wrapper
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{duration: 0.5}}
      key="step1"
    >
      <AnimatedStep step="1" />
      <Styles.Title>Choose the genre :</Styles.Title>

      <Styles.GenreGrid>
        <Styles.Genre onMouseEnter={handleMouseOver} onClick={() => handleGenreClick('pop')}>
          <div>POP</div>
        </Styles.Genre>
        <Styles.Genre onMouseEnter={handleMouseOver} onClick={() => handleGenreClick('rock')}>
          <div>ROCK</div>
        </Styles.Genre>
        <Styles.Genre onMouseEnter={handleMouseOver} onClick={() => handleGenreClick('hiphop')}>
          <div>HIP-HOP</div>
        </Styles.Genre>
        <Styles.Genre onMouseEnter={handleMouseOver}  onClick={() => handleGenreClick('country')}>
          <div>COUNTRY</div>
        </Styles.Genre>
        <Styles.Genre onMouseEnter={handleMouseOver}  onClick={() => handleGenreClick('blues')}>
          <div>BLUES</div>
        </Styles.Genre>
        <Styles.Genre onMouseEnter={handleMouseOver} onClick={() => handleGenreClick('metal')}>
          <div>METAL</div>
        </Styles.Genre>
      </Styles.GenreGrid>
    </Styles.Wrapper>
  );
}

export default Step1;