import styled from 'styled-components';
import { motion } from 'framer-motion';

import backgroundRec from '../assets/rectangle-background.svg';
import arrowSrc from '../assets/arrow.svg';


export const Wrapper = styled(motion.div)`
  position: relative;
  padding: 0 40px;
`;

export const ArrowRow = styled.div`
  position: absolute;
  display: flex;
  top: -15px;
  justify-content: space-between;
  z-index: 1;
`;

export const ArrowBack = styled.div`
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &:hover {
    path {
      fill: #FB0300;
    }
  }
`;

export const ArrowForward = styled.div`
  display: block;
  background-image: url(${arrowSrc});
  height: 40px;
  width: 40px;
  transform: rotate(180deg);
`;

export const StepWrapper = styled.div`
  font-family: 'riffic', sans-serif;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 76px;
  margin-bottom: 0px;
  z-index: 1;
  pointer-events: none;

  svg {
    z-index: 0;
    position: absolute;
    top: -60px;
    left: -28px;
    width: 160px !important;
    height: 160px !important;
  }
`;

export const CurrentStep = styled.h2`
  font-size: 44px;
  margin-right: 6px;
  position: relative;
  z-index: 1;
`;

export const MaxSteps = styled.h3`
  font-size: 22px;
  position: relative;
  z-index: 1;
`;

export const Title = styled(motion.div)`
  color: #fff;
  font-family: 'riffic', sans-serif;
  font-size: 48px;
  background-image: url(${backgroundRec});
  position: relative;
  top: -10px;
  left: -325px;
  padding: 67px 97px;
  min-width: 650px;

  z-index: 0;
`;

export const GenreGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 26px;
`;

export const Genre = styled.button`
  cursor: pointer;
  font-family: 'riffic', sans-serif;
  font-size: 20px;
  position: relative;
  background-color: transparent;

  div {
    display: block;
    color: #020410;
    border-radius: 88px;
    background: #fff;
    z-index: 1;
    position: relative;
    padding: 16px 0;
    transition: color 0.2s;

    &:hover {
      color: #fff;
      background-color: #FB0300;
    }
  }

  svg {
    position: absolute;
    left: -55px;
    top: -50px;
    height: 150px !important;
    width: 300px !important;
    z-index: 0;
    pointer-events: none;
  }
`;

export const Hint = styled.p`
  font-family: 'riffic', sans-serif;
  font-size: 16px;
  color: #B2B2BE;
  margin-bottom: 38px;
`;

export const Input = styled.textarea`
  font-family: 'Manrope', sans-serif;
  background-color: #111119;
  border: 2px solid #3B3B3E;
  padding: 24px;
  color: #fff;
  border-radius: 20px;
  margin-bottom: 72px;
  font-size: 20px;
  height: 234px;
  width: 100%;
`;

export const CTA = styled.button`
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  font-family: 'riffic', sans-serif;
  color: #fff;
  background-color: ${props => props.disabled ? '#14182E' : '#FB0300'};
  font-size: 20px;
  border-radius: 88px;
  padding: 16px 0;
  transition: color 0.2s;
  min-width: 222px;
  text-transform: uppercase;
  transition: background-color 0.2s;

  &:hover {
    color: #020410;
    background: #fff;
  }

  svg {
    position: absolute;
    left: -45px;
    top: -50px;
    height: 150px !important;
    width: 300px !important;
    z-index: 0;
    pointer-events: none;
  }
`;

export const RedCTA = styled.button`
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  font-family: 'riffic', sans-serif;
  color: #fff;
  font-size: 20px;
  min-width: 222px;
  text-transform: uppercase;
  background: transparent;

  div {
    display: block;
    color: #fff;
    border-radius: 88px;
    background: #FB0300;
    z-index: 1;
    position: relative;
    padding: 16px 0;
    transition: color 0.2s;
    background-color: ${props => props.disabled ? '#14182E' : '#FB0300'};
  }

  svg {
    position: absolute;
    left: -45px;
    top: -50px;
    height: 150px !important;
    width: 300px !important;
    z-index: 0;
    pointer-events: none;
  }
`;

export const CTAanimContainer = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: transparent;
  width: 100%;
`;

export const BackgroundContainer = styled.div`
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
`;

export const FinalMomentWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: #111119;
  color: white;
  opacity: ${props => props.isLive ? '1' : '0'};
  transition: 0.2s opacity;
  pointer-events: none;

  svg {
    width: 40% !important;
    margin: 0 auto;
    position: relative;
    display: block;
  }
`;

export const FinalMomentLoader = styled(motion.div)`
  font-family: 'riffic', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.02em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  opacity: ${props => props.isLive ? '1' : '0'};
  transition: 0.2s opacity;
`;

export const Note1 = styled.div`
  height: 183px;
  width: 180px;
  position: absolute;
  bottom: -130px;
  left: -170px;
`;

export const Note2 = styled.div`
  height: 183px;
  width: 180px;
  position: absolute;
  right: -160px;
  bottom: -20px;
`;