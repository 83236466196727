export default {
  rock: [
    {
      url: '/audio/rock/track1.mp3',
      delay: 10000
    },
    {
      url: '/audio/rock/track2.mp3',
      delay: 13000
    },
    {
      url: '/audio/rock/track3.mp3',
      delay: 12000
    },
    {
      url: '/audio/rock/track4.mp3',
      delay: 18000
    },
    {
      url: '/audio/rock/track5.mp3',
      delay: 30000
    }
  ],
  pop: [
    {
      url: '/audio/pop/track1.mp3',
      delay: 12000
    },
    {
      url: '/audio/pop/track2.mp3',
      delay: 8000
    },
    {
      url: '/audio/pop/track3.mp3',
      delay: 9000
    },
    {
      url: '/audio/pop/track4.mp3',
      delay: 13000
    },
    {
      url: '/audio/pop/track5.mp3',
      delay: 8000
    }
  ],
  hiphop: [
    {
      url: '/audio/hiphop/track1.mp3',
      delay: 5000
    },
    {
      url: '/audio/hiphop/track2.mp3',
      delay: 12000
    },
    {
      url: '/audio/hiphop/track3.mp3',
      delay: 5000
    },
    {
      url: '/audio/hiphop/track4.mp3',
      delay: 5000
    },
    {
      url: '/audio/hiphop/track5.mp3',
      delay: 10000
    }
  ],
  country: [
    {
      url: '/audio/country/track1.mp3',
      delay: 10000
    },
    {
      url: '/audio/country/track2.mp3',
      delay: 8000
    },
    {
      url: '/audio/country/track3.mp3',
      delay: 12000
    },
    {
      url: '/audio/country/track4.mp3',
      delay: 8000
    },
    {
      url: '/audio/country/track5.mp3',
      delay: 5000
    }
  ],
  blues: [
    {
      url: '/audio/blues/track1.mp3',
      delay: 4000
    },
    {
      url: '/audio/blues/track2.mp3',
      delay: 12000
    },
    {
      url: '/audio/blues/track3.mp3',
      delay: 15000
    },
    {
      url: '/audio/blues/track4.mp3',
      delay: 10000
    },
    {
      url: '/audio/blues/track5.mp3',
      delay: 17000
    }
  ],
  metal: [
    {
      url: '/audio/metal/track1.mp3',
      delay: 13000
    },
    {
      url: '/audio/metal/track2.mp3',
      delay: 22000
    },
    {
      url: '/audio/metal/track3.mp3',
      delay: 11000
    },
    {
      url: '/audio/metal/track4.mp3',
      delay: 5000
    },
    {
      url: '/audio/metal/track5.mp3',
      delay: 12000
    }
  ]
}