import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  z-index: 1000;
  background-color: #020410;

  @media (max-width: 450px) {
    display: none;
  }

  div {
    width: 600px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;
