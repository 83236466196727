import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: absolute;
  color: #fff;
  font-size: 32px;
  font-weight: 800;
  height: 400px;
  width: 650px;
  overflow: hidden;
  text-align: center;
  line-height: 110%;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);

  span {
    display: inline-block;
  }

  strong {
    color: #e6cc00;
  }
`;

export const Container = styled.div`
  position: relative;
  padding: 15px 0;

  h2 {
    font-family: 'riffic', sans-serif;
    font-size: 42px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
  }
`;
