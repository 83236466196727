import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { useGlobalState } from '../components/GlobalStateProvider';
import Step1 from '../components/Step1';
import Step2 from '../components/Step2';
import Step3 from '../components/Step3';

import Welcome from '../components/Welcome';

import * as Styles from './builder.styles';
import FinalStep from '../components/FinalStep';

function Builder() {
  const { globalState, setGlobalState } = useGlobalState();

  if (globalState.position === 5) return <FinalStep />;

  return (
    <>
      {globalState.position === 1 && <Welcome />}
      <Styles.Wrapper width={globalState.isFinalAnimation || globalState.position === 1 ? '0%' : '30%'}>
        <AnimatePresence>
          {globalState.position === 2 && <Step1 />}
          {globalState.position === 3 && <Step2 />}
          {globalState.position === 4 && <Step3 />}
        </AnimatePresence>
      </Styles.Wrapper>
    </>
  );
}

export default Builder;