import React, { useRef, useEffect } from 'react';
import bodymovin from 'bodymovin';

import { useGlobalState } from '../components/GlobalStateProvider';

import * as Styles from './Welcome.styles';

let firstAnim = null;
let secondAnim = null;

function Welcome() {
  const { globalState, setGlobalState } = useGlobalState();
  const titleRef = useRef(null);
  let lottie = null;
  const star1Ref = useRef(null);
  const star2Ref = useRef(null);

  useEffect(() => {
    if (star1Ref.current && !lottie && firstAnim !== undefined) {
      firstAnim = bodymovin
        .loadAnimation({
          container: star1Ref.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          name: "note-two",
          path: "/animations/note-two.json",
        })
        .setSpeed(1);
    }

    if (star2Ref.current && !lottie && secondAnim !== undefined) {
      secondAnim = bodymovin
        .loadAnimation({
          container: star2Ref.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          name: "note-one",
          path: "/animations/note-one.json",
        })
        .setSpeed(1);
    }
  }, [star1Ref, lottie]);

  const handleStartClick = (event) => {
    setGlobalState({ ...globalState, position: globalState.position + 1 });
  };

  const handleMouseOver = (event) => {
    const lottie = bodymovin.loadAnimation({
      container: event.currentTarget, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      name: 'mouseover',
      path: '/animations/notes.json' // the path to the animation json
    }).setSpeed(2);
  }

  useEffect(() => {
    if (titleRef.current && !lottie) {
      bodymovin.loadAnimation({
        container: titleRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'star-background',
        path: '/animations/undertitle.json'
      }).setSpeed(2);
    }
  }, [titleRef, lottie]);

  return (
    <Styles.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{duration: 0.5}}
      key="welcome"
    >
      <Styles.Title ref={titleRef}>
        <span>Hey there, music lover!</span>
        <strong>Welcome to AI.ROCKE</strong>
      </Styles.Title>

      <Styles.Intro>
        <p>Get ready to sing your heart out to your favorite tunes, while our mind-blowing AI technology takes care of lyricis and backing track.</p>
        <br />
        <br />
        <Styles.Note1 ref={star1Ref} />
        <strong>THE SPOTLIGHT’S ON YOU, HAPPY SINGING!</strong>
        <Styles.Note2 ref={star2Ref} />

        <Styles.CTA onMouseEnter={handleMouseOver} onClick={handleStartClick}>
          <div>LET'S ROCK IT</div>
        </Styles.CTA>
      </Styles.Intro>
    </Styles.Wrapper>
  )
}

export default Welcome;